<template>
  <div class="pub-title" @mouseleave="notshow = false">
    <div
      class="top"
      :style="{
        background: activeIndex == 0 ? 'rgba(4, 14, 46, 0.5)' : '#040E2E',
      }"
      @mouseenter="(notshow = true), (activeChild = activeIndex)"
    >
      <div class="title">
        <div class="logo" @click="go('home')">
          <img src="@/assets/image/logo.svg" alt="" />
        </div>
        <div class="nav-bar">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            text-color="#fff"
            active-text-color="#fff"
            @select="handleSelect"
          >
            <el-menu-item v-for="(item, i) in navData" :key="i" :index="item.index"
              ><span @mouseenter="activeChild = i">{{ item.value }}</span></el-menu-item
            >
          </el-menu>
        </div>
      </div>
    </div>
    <!-- <div v-if="activeChild != '1' && notshow && childList != 0" v-if="activeChild == '1' && notshow"> -->
    <transition name="t-content" v-if="activeChild != '1' && notshow && childList != 0">
      <div
        class="content"
        v-if="activeChild != '1' && notshow && childList != 0"
        :class="{ 'new-tec-style': activeChild == 1 }"
      >
        <div class="c-item-area">
          <div
            class="c-item"
            v-for="(citem, ci) in childList"
            :key="ci"
            @click="handleIdSelect(activeChild, citem)"
          >
            {{ citem.label }}
          </div>
        </div>
        <!-- 全新改版 改版1 貌似已经费了 -->
      </div>
    </transition>
    <transition name="t-content" v-if="activeChild == '1' && notshow">
      <div
        class="content"
        v-if="activeChild == '1' && notshow"
        :class="{ 'new-tec-style': activeChild == 1 }"
      >
        <!-- 改版2 临时性设计 通过了 就这样用了 -->
        <div class="style-technology">
          <div class="left">
            <!-- 新增改版功能3。 -->
            <div class="tec-m-title" @click="goPrd('/product?id=qqst')">
              全球视听内容分析
            </div>
            <div class="t-item-list">
              <div
                class="t-item-item"
                v-for="(litem, li) in left"
                :key="li"
                @click="handleIdSelect(activeChild, litem, 'qqst')"
              >
                {{ litem.label }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="tec-m-title" @click="goPrd('/product?id=gjcb')">国际传播矩阵</div>
            <div class="t-item-list">
              <div
                class="t-item-item"
                v-for="(ritem, ri) in right"
                :key="ri"
                @click="handleIdSelect(activeChild, ritem, 'gjcb')"
              >
                {{ ritem.label }}
              </div>
            </div>
          </div>
          <div class="right2">
            <div class="tec-m-title" @click="goPrd('/product?id=kjds')">跨境电商</div>
            <div class="t-item-list">
              <div
                class="t-item-item"
                v-for="(ritem, ri) in bottom"
                :key="ri"
                @click="handleIdSelect(activeChild, ritem, 'kjds')"
              >
                {{ ritem.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import data from '@/utils/data';
export default {
  computed: {
    childList() {
      return this.navData[this.activeChild].children;
    },
  },
  mixins: [data],
  data() {
    return {
      activeIndex: '0',
      notshow: false,
      activeChild: 0,
      i: 0,
      left: [
        {
          label: '视频信息分析解决方案',
          path: '/product/solution/product01',
        },
        {
          label: '直播信息分析解决方案',
          path: '/product/solution/product02',
        },
      ],
      right: [
        {
          label: '传播矩阵布局',
          id: '',
          path: '/product/solution/product03',
        },
        {
          label: '内容传播生产',
          id: '',
          path: '/product/solution/product04',
        },
        {
          label: '人机交互传播',
          path: '/product/solution/product05',
        },
      ],
      bottom: [
        {
          label: '跨境直播',
          path: '/product/solution/product06',
        },
        {
          label: '一站式跨境电商',
          path: '/product/solution/product07',
        },
        {
          label: '文娱严选',
          path: '/product/solution/product08',
        },
      ],
    };
  },
  watch: {
    $route(to) {
      let path = to.path;
      this.navData.forEach((e) => {
        let ep = e.path.split('?')[0];
        if (path.indexOf(ep) !== -1) {
          this.activeIndex = e.index;
        }
      });
    },
  },
  mounted() {
    let path = this.$route.path;
    this.navData.forEach((e) => {
      let ep = e.path.split('?')[0];
      if (path.indexOf(ep) !== -1) {
        e.index != this.activeIndex && (this.activeIndex = e.index);
      }
    });
  },
  methods: {
    handleSelect(key) {
      let index = Number(key);
      if (this.activeIndex != index && this.navData[index].path) {
        this.activeIndex = index + '';
        this.$router.push(this.navData[index].path);
      }
    },
    handleIdSelect(key, val) {
      let index = Number(key);
      this.activeIndex = index + '';
      if (this.activeIndex == '1' || this.activeIndex == '2') {
        this.$router.push(val.path);
        return;
      }
      if (this.activeIndex == '4') {
        let path = val.path + '?id=' + val.type;
        this.$router.push(path);
        return;
      }
      if (val.id) {
        let id = val.id;
        this.$router.push(this.navData[index].path + (id ? '/#' + id : ''));
      } else {
        // 功能简单，简易处理~
        this.i = ++this.i;
        let params = {
          i: this.i,
          type: val.type,
        };
        this.$router.push({ path: this.navData[index].path, query: params });
      }
    },
    go(path) {
      if (path == 'home') {
        this.$router.push('/');
      }
    },
    goPrd(path) {
      (this.activeIndex = '1'), this.$router.push(path);
    },
    showList(val) {
      this.activeChild = val;
    },
    goPage(index) {
      this.activeIndex = ++index + '';
      this.$router.push(this.navData[this.activeChild].path);
    },
  },
};
</script>
<style lang="scss" scoped>
.pub-title {
  z-index: 99;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: #040E2E;

  .logo {
    img {
      width: 310px;
    }
    margin-right: 40px;
  }
  .top {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 90px;
    // background: rgba(4, 14, 46, 0.5);
    background: #040e2e;
  }
  .title {
    width: 100%;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    line-height: 70px;
    // height: 70px;
    width: 100%;
    color: #333;
    // background: rgba(141, 141, 141, 0.6);
    background: #040e2e;
    display: flex;
    justify-content: center;
    padding-top: 0;
  }
  .c1-active {
    // height: 70px;
    transform: height 1s;
  }
  .content {
    .c-item-area {
      // width: 1400px;
      padding: 0 40px;
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: right;
      // margin-right: -310px;
      padding-left: 350px;
    }
  }

  .c-item {
    margin: 0 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    &:hover {
      color: #fff;
    }
  }

  .new-tec-style {
    // display: block !important;
  }
  .style-technology {
    // width: 1400px;
    width: 100%;
    padding-right: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: right;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .tec-m-title {
    font-size: 18px;
    cursor: pointer;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    line-height: 25px;
    &:hover {
      color: #fff;
    }
  }
  .t-item-item {
    cursor: pointer;
    height: 22px;
    margin-top: 10px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    line-height: 22px;
    &:hover {
      color: #fff;
    }
  }
  .right {
    margin-right: 50px;
  }
  .left {
    margin-right: 50px;
  }
}

.t-content-enter-active {
  transition: opacity 0.5s;
}
.t-content-enter {
  opacity: 0;
}
.t-content-leave-active {
  transition: opacity 0.5s;
}
.t-content-leave-to {
  opacity: 0;
}

.pub-title {
  .el-menu {
    background: none !important;
    color: #fff;
  }
  .el-menu-item {
    &:hover {
      color: #6e59d0 !important;
    }
  }
  .el-submenu__title,
  .el-menu-item,
  .el-menu-item .is-active {
    &:hover {
      background: none !important;
    }
    background: none !important;
  }
  .el-menu-item {
    margin: 0 20px !important;
    padding: 0;
    font-size: 18px;
    line-height: 90px !important;
    height: 90px !important;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu-demo {
    height: 90px;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom-color: #6e59d0 !important;
    border-width: 4px;
    // border-radius: 2px;
  }
}
</style>
